import api from "@/lib/api";

export default {
  namespaced: true,
  actions: {
    uploadFile(context, file) {
      return new Promise((resolve, reject) => {
        let fd = new FormData
        fd.append('file', file)
        api.post('/internal/upload', fd, false).then(res => {
          if (!res.error) {
            resolve(res.data)
          }
          reject('err', res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
