<template>
  <div class="has-aside-left has-aside-mobile-transition has-navbar-fixed-top has-aside-expanded">
    <div id="app">
      <nav-bar/>
      <aside-menu :menu="filteredMenu"/>
      <router-view/>
      <footer-bar/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import {mapGetters} from "vuex";

export default {
  name: 'Main',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
    menu() {
      return [
        {
          title: 'Book',
          menu:
            [
              {
                to: '/book/overview',
                icon: 'desktop-mac',
                label: 'Overview',
                role: ['master'],
              },
              {
                to: '/book/farms',
                icon: 'chart-bubble',
                label: 'Farms',
                role: ['master'],
              },
              {
                to: '/book/harvest',
                icon: 'chart-pie',
                label: 'Harvest',
                role: ['master'],
              },
            ],
        },
        {
          title: 'Hatchery',
          menu: [
            {
              to: '/hatchery/overview',
              icon: 'fishbowl-outline',
              label: 'Overview',
              role: ['master'],
            },
          ],
        },
        {
          title: 'Supply',
          menu: [
              {
                to: '/supply/overview',
                icon: 'warehouse',
                label: 'Overview',
                role: ['master'],
              },
            ],
        },
        // {
        //   title: 'Finance',
        //   menu: [
        //     {
        //       to: '/finance/overview',
        //       icon: 'currency-usd',
        //       label: 'Overview',
        //       role: ['master'],
        //     },
        //     {
        //       to: '/finance/financing',
        //       icon: 'file-chart',
        //       label: 'Financing',
        //       role: ['master'],
        //     },
        //   ],
        // },
        {
          title: 'Supply Chain',
          menu:  [
            {
              to: '/supply-chain',
              icon: 'note-text',
              label: 'Supply Chain',
              role: ['master', 'supply_chain']
            },
          ],
        },
        {
          title: 'General',
          menu:[
            {
              to: '/users',
              icon: 'account-multiple',
              label: 'Users',
              role: ['master'],
            },
            /*
            {
              label: 'Submenus',
              subLabel: 'Submenus Example',
              icon: 'view-list',
              menu: [
                {
                  href: '#void',
                  label: 'Sub-item One'
                },
                {
                  href: '#void',
                  label: 'Sub-item Two'
                }
              ]
            }
             */
          ],
        },
      ]
    },
    filteredMenu() {
      return this.menu.map(e => {
        e.menu = e.menu.filter(f => !f.role || f.role.includes(this.user.data.dashboard_role))
        return e
      }).filter(e => e.menu && e.menu.length)
    },
  },
}
</script>
