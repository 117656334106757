<template>
  <div id="app">
    <b-loading :is-full-page="true" v-model="loading" :can-cancel="true"></b-loading>
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'App',
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set() {
      },
    },
  }
}
</script>
