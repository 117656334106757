import api from "@/lib/api";

const supply = {
  namespaced: true,
  state: {
    hatcheries: {
      data: [],
      loading: false,
    },
    hatchery_customers: {
      data: [],
      loading: false,
    },
    metrics: {
      total_pl: {
        value: 0,
        loading: false,
      },
    },
  },
  mutations: {
    UPDATE_HATCHERIES(state, payload) {
      state.hatcheries.data = payload.data
      state.hatcheries.loading = payload.loading
    },
    UPDATE_HATCHERY_CUSTOMERS(state, payload) {
      state.hatchery_customers.data = payload.data
      state.hatchery_customers.loading = payload.loading
    },
    UPDATE_METRICS(state, payload) {
      state.metrics[payload.key].value = payload.value
      state.metrics[payload.key].loading = payload.loading
    },
  },
  actions: {
    getHatcheries({commit}, payload) {
      commit('UPDATE_HATCHERIES', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/hatchery/rank', payload).then(res => {
          console.log(res.data)
          if (!res.error && res.data.length) commit('UPDATE_HATCHERIES', {
            data: res.data,
            loading: false
          })
          else
            commit('UPDATE_HATCHERIES', {
              data: [],
              loading: false
            })

          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getHatcheryCustomers({commit}, payload) {
      commit('UPDATE_HATCHERY_CUSTOMERS', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/hatchery/customer/company', payload).then(res => {
          if (!res.error) commit('UPDATE_HATCHERY_CUSTOMERS', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    countPl({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'total_pl',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/hatchery/pl/total', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'total_pl',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'total_pl',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
  },
}

export default supply
