import api from "@/lib/api";

const user = {
  namespaced: true,
  state: {
    users: {
      data: [],
      loading: false
    }
  },
  mutations: {
    UPDATE_USERS(state, payload) {
      state.users.data = payload.data
      state.users.loading = payload.loading
    },
  },
  actions: {
    getUsers({commit}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_USERS', {
          data: [],
          loading: true
        })

        api.get('/internal/user').then(res => {
          if (!res.error) commit('UPDATE_USERS', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUser(context, payload) {
      return new Promise((resolve, reject) => {
        api
          .put('/dashboard/general/user/' + payload.user_id + '/update', payload, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteUser(context, userId) {
      return new Promise((resolve, reject) => {
        api
          .destroy('/dashboard/general/user/' + userId + '/delete')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}

export default user
