import Vue from 'vue'
import store from '@/store'

const loading = (value = true) => {
  store.dispatch('loading', value)
}

const timestamp = (value = null) => {
  let date = value ? Vue.prototype.$moment(new Date(value)) : Vue.prototype.$moment()
  return date.valueOf()
}

const dateFormat = (value = null, format = 'DD MMM YYYY') => {
  if (value) return Vue.prototype.$moment(new Date(value)).format(format)
  else return Vue.prototype.$moment().format(format)
}

const startDay = (value = null) => {
  if (value) return Vue.prototype.$moment(value).startOf('day')
  else return Vue.prototype.$moment().startOf('day')
}

const isSameDay = (a = null, b = null) => {
  a = startDay(a).valueOf()
  b = startDay(b).valueOf()

  return a === b
}

const timeFormat = (value = null, format = 'HH:mm') => {
  if (value) return Vue.prototype.$moment(new Date(value)).format(format)
  else return Vue.prototype.$moment().format(format)
}

const currency = (value) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  })

  let result = formatter.format(value)

  return result.replace('IDR', 'Rp')
}

const numberFormat = (value) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0
  })

  let result = formatter.format(value)

  return result.replace('IDR', 'Rp')
}

// let fnDeleteDialog = null

// const deleteDialog = async (fn = null, is_submit = false, opt = {}) => {
//   if (fn) fnDeleteDialog = fn
//   store.dispatch('deleteDialog', !is_submit)
//   store.dispatch('deleteOpt', opt)

// if (is_submit) {
//   if (typeof fnDeleteDialog === 'function') await fnDeleteDialog()
//   fnDeleteDialog = null
// }
// }

const diffDays = (date1 = null, date2 = null) => {
  let from = date1 ? Vue.prototype.$moment(new Date(Vue.prototype.$moment(date1))) : Vue.prototype.$moment()
  return from.diff(Vue.prototype.$moment(date2 ? new Date(date2) : new Date()), 'days')
}

const base64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file && file.name && typeof file.name === 'string') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    } else resolve(file)
  });
}

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
}

// const lang = () => {
//   return i18n.locale
// }

// const labelLang = () => {
//   return i18n.locale === 'en' ? 'label_en' : 'label_id'
// }

const number = (n = 0, decimal = 2) => {
  return +parseFloat(n).toFixed(decimal)
}

const exponential = (value) => {
  if (value)
    return parseFloat(value).toExponential().replace('e', ' x 10^').replace('+', '')
  return null
}

const isImage = (url) => {
  return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

const plugin = {
  install() {
    Vue.prototype.$loading = loading
    Vue.prototype.$timestamp = timestamp
    Vue.prototype.$currency = currency
    // Vue.prototype.$delete = deleteDialog
    Vue.prototype.$dateFormat = dateFormat
    Vue.prototype.$startDay = startDay
    Vue.prototype.$isSameDay = isSameDay
    Vue.prototype.$timeFormat = timeFormat
    Vue.prototype.$diffDays = diffDays
    // Vue.prototype.$getColor = getColor()
    // Vue.prototype.$getColorLabel = getColorLabel
    // Vue.prototype.$getColorById = getColorById
    // Vue.prototype.$getWeather = getWeather()
    // Vue.prototype.$getWeatherLabel = getWeatherLabel
    // Vue.prototype.$getWeatherById = getWeatherById
    // Vue.prototype.$lang = lang()
    // Vue.prototype.$labelLang = labelLang()
    Vue.prototype.$unique = unique
    Vue.prototype.$base64 = base64
    Vue.prototype.$number = number
    Vue.prototype.$numberFormat = numberFormat
    Vue.prototype.$exponential = exponential
    Vue.prototype.$isImage = isImage
  }
}

export default plugin
