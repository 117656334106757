import api from "@/lib/api";
import Vue from "vue";

const supplyChain = {
  namespaced: true,
  state: {
    nota_panen: {
      data: [],
      loading: false
    },
    delivery_note: {
      data: [],
      loading: false
    },
    selectedDate: null,
    selectedFrom: null,
    selectedTo: null,
  },
  getters: {
    financeData: state => {
      return [
        ...state.nota_panen.data.filter(e => e.total_price).map(e => {
          return {
            from: 'Sgara',
            to: e.farmer_name,
            payment_date: e.payment_date,
            total_price: e.total_price,
            harvest_date: e.harvest_date,
            payment_time: e.payment_time ? (e.payment_time + ' Minggu') : 'Cash',
            sisa: e.sisa,
            lunas: e.sisa === 'lunas',
            type: 'expenses',
          }
        }),
        ...state.delivery_note.data.filter(e => e.total_price).map(e => {
          return {
            from: e.farmer_name.split(',').join(', '),
            to: e.destination,
            total_price: e.total_price,
            payment_date: e.payment_date,
            payment_time: e.payment_time ? (e.payment_time + ' Hari') : 'Cash',
            payment_paid: e.payment_paid,
            remaining_payment: e.payment_paid ? e.total_price - e.payment_paid : null,
            harvest_date: e.harvest_date,
            sisa: Vue.prototype.$diffDays(e.payment_date),
            lunas: !!e.payment_file,
            type: 'income',
          }
        })
      ]
    },
    fromData: (state, getters) => getters.financeData.reduce((a, b) => {
      if (!a.includes(b.from)) a.push(b.from)
      return a
    }, []),
    toData: (state, getters) => getters.financeData.reduce((a, b) => {
      if (!a.includes(b.to)) a.push(b.to)
      return a
    }, []),
    financeHistory: (state, getters) => {
      let data =  getters.financeData.filter(e => {
        if (state.selectedDate)
          return e.payment_date <= state.selectedDate
        return e.lunas
      })
      if (state.selectedFrom) data = data.filter(e => e.from === state.selectedFrom)
      if (state.selectedTo) data = data.filter(e => e.to === state.selectedTo)
      return data
    },
    upcomingFinance: (state, getters) => {
      let data=  getters.financeData.filter(e => {
        if (state.selectedDate)
          return e.payment_date > state.selectedDate
        return !e.lunas
      })
      if (state.selectedFrom) data = data.filter(e => e.from === state.selectedFrom)
      if (state.selectedTo) data = data.filter(e => e.to === state.selectedTo)
      return data
    },
    totalIncome: (state, getters) => {
      return getters.financeHistory.reduce((a, b) => {
        if (b.type === 'income') a += b.total_price
        return a
      }, 0)
    },
    totalExpenses: (state, getters) => {
      return getters.financeHistory.reduce((a, b) => {
        if (b.type === 'expenses') a += b.total_price
        return a
      }, 0)
    },
    currentBalance: (state, getters) => {
      return getters.totalIncome - getters.totalExpenses
    },
    totalUpcomingIncome: (state, getters) => {
      return getters.upcomingFinance.reduce((a, b) => {
        if (b.type === 'income') a += b.total_price
        return a
      }, 0)
    },
    totalUpcomingExpenses: (state, getters) => {
      return getters.upcomingFinance.reduce((a, b) => {
        if (b.type === 'expenses') a += b.total_price
        return a
      }, 0)
    },
    upcomingBalance: (state, getters) => {
      return getters.totalUpcomingIncome - getters.totalUpcomingExpenses
    },
  },
  mutations: {
    UPDATE_NOTA_PANEN(state, payload) {
      state.nota_panen.data = payload.data
      state.nota_panen.loading = payload.loading
    },
    UPDATE_DELIVERY_NOTE(state, payload) {
      state.delivery_note.data = payload.data
      state.delivery_note.loading = payload.loading
    },
  },
  actions: {
    getNotaPanen({commit}) {
      commit('UPDATE_NOTA_PANEN', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/nota-panen').then(res => {
          let data = []
          if (!res.error) {
            data = res.data.map(e => {
              e.sisa = '-'
              if (e.payment_file) {
                e.sisa = 'lunas'
              } else {
                if (e.payment_method === 'Tempo Pembayaran')
                  e.sisa = Vue.prototype.$diffDays(e.payment_date)
                else if (e.payment_method === 'Cash')
                  e.sisa = Vue.prototype.$diffDays(e.harvest_date)
              }

              e.total_price = e.crops.reduce((a, b) => {
                if (b.qty && b.price_per_kg)
                  return a + (b.qty * b.price_per_kg)
                return a
              }, 0)
              return e
            })
          }
          commit('UPDATE_NOTA_PANEN', {
            data: data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getNotaPanenByCropID({commit}, crop_ids) {
      return new Promise((resolve, reject) => {
        api.get('/internal/nota-panen/crops', {
          crop_ids: crop_ids.join(','),
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getNotaPanenByID({commit}, nota_panen_id) {
      return new Promise((resolve, reject) => {
        api.get('/internal/nota-panen/' + nota_panen_id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },

    addNotaPanen(context, payload) {
      return new Promise((resolve, reject) => {
        api
          .post('/internal/nota-panen', payload, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateNotaPanen(context, payload) {
      return new Promise((resolve, reject) => {
        api
          .put('/internal/nota-panen/' + payload.nota_panen_id + '/update', payload, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getDeliveryNote({commit}) {
      commit('UPDATE_DELIVERY_NOTE', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/delivery-note').then(res => {
          let data = []
          if (!res.error) {
            data = res.data.map(e => {
              e.total_qty = e.delivery_boxes.reduce((a, b) => {
                for (let i = 0; i < b.delivery_box_crops.length; i++) {
                  a += b.delivery_box_crops[i].qty
                }
                return a
              }, 0)
              e.selisih_tonase = e.bongkar_qty ? Vue.prototype.$number(e.bongkar_qty - e.total_qty) : null
              e.selisih_tonase_percentage = e.selisih_tonase ? Vue.prototype.$number((e.selisih_tonase / e.total_qty) * 100) : null
              return e
            })
          }
          commit('UPDATE_DELIVERY_NOTE', {
            data: data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getDeliveryNoteByID({commit}, delivery_note_id) {
      return new Promise((resolve, reject) => {
        api.get('/internal/delivery-note/' + delivery_note_id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    addDeliveryNote(context, payload) {
      return new Promise((resolve, reject) => {
        api
          .post('/internal/delivery-note', payload, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateDeliveryNote(context, payload) {
      return new Promise((resolve, reject) => {
        api
          .put('/internal/delivery-note/' + payload.delivery_note_id + '/update', payload, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteNotaPanen(context, nota_panen_id) {
      return new Promise((resolve, reject) => {
        api
          .destroy('/internal/nota-panen/' + nota_panen_id + '/delete')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteDeliveryNote(context, delivery_note_id) {
      return new Promise((resolve, reject) => {
        api
          .destroy('/internal/delivery-note/' + delivery_note_id + '/delete')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateFarm(context, payload) {
      const {farm_id, status} = payload

      return new Promise((resolve, reject) => {
        api
          .put('/internal/supply-chain/' + farm_id + '/update/status', {
            status,
          }, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getFarmInfo(context, farmId) {
      return new Promise((resolve, reject) => {
        api
          .get('/internal/supply-chain/' + farmId + '/info')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}

export default supplyChain
