import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from "@/store/modules/auth";
import farm from "@/store/modules/farm";
import user from "@/store/modules/user";
import supply from "@/store/modules/supply";
import harvest from "@/store/modules/harvest";
import finance from "@/store/modules/finance";
import hatchery from "@/store/modules/hatchery";
import company from "@/store/modules/company";
import supplyChain from "@/store/modules/supplyChain";
import upload from "@/store/modules/upload";

export default new Vuex.Store({
  modules: {
    auth,
    farm,
    user,
    supply,
    harvest,
    finance,
    hatchery,
    company,
    supplyChain,
    upload,
  },
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,
    loading: false,
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    SET_LOADING(state, payload = true) {
      state.loading = payload
    },
  },
  actions: {
    loading({commit}, payload = true) {
      commit('SET_LOADING', payload)
    },
  }
})
