import api from "@/lib/api";

const harvest = {
  namespaced: true,
  state: {
    harvest: {
      data: [],
      loading: false
    },
    harvest_shrimp_size: {
      data: [],
      loading: false
    },
    metrics: {
      partial_harvest: {
        value: 0,
        loading: false,
      },
      total_harvest: {
        value: 0,
        loading: false,
      },
      fresh_shrimp: {
        value: 0,
        loading: false,
      },
    }
  },
  mutations: {
    UPDATE_HARVEST(state, payload) {
      state.harvest.data = payload.data
      state.harvest.loading = payload.loading
    },
    UPDATE_SHRIMP_SIZE(state, payload) {
      state.harvest_shrimp_size.data = payload.data
      state.harvest_shrimp_size.loading = payload.loading
    },
    UPDATE_METRICS(state, payload) {
      state.metrics[payload.key].value = payload.value
      state.metrics[payload.key].loading = payload.loading
    }
  },
  actions: {
    getHarvest({commit}, payload) {
      commit('UPDATE_HARVEST', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/prod/location', payload).then(res => {
          if (!res.error) commit('UPDATE_HARVEST', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getShrimpSizeChart({commit}) {
      commit('UPDATE_SHRIMP_SIZE', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/chart/size/location').then(res => {
          if (!res.error) commit('UPDATE_SHRIMP_SIZE', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getShrimpSizeChartByMonth({commit}, {month, year}) {
      commit('UPDATE_SHRIMP_SIZE', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/chart/size/month', {
          month, year,
        }).then(res => {
          if (!res.error) commit('UPDATE_SHRIMP_SIZE', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getShrimpSizeChartByYear({commit}, {year}) {
      commit('UPDATE_SHRIMP_SIZE', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/chart/year', {
          year,
        }).then(res => {
          if (!res.error) commit('UPDATE_SHRIMP_SIZE', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    countPartialHarvest({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'partial_harvest',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/partial/total', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'partial_harvest',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'partial_harvest',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countTotalHarvest({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'total_harvest',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/total', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'total_harvest',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'total_harvest',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countFreshShrimp({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'fresh_shrimp',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest-menu/fresh/total', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'fresh_shrimp',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'fresh_shrimp',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
  }
}

export default harvest
