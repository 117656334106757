import api from '@/lib/api'
import router from "@/router"

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('sgara-dashboard-psql.user'))
  },
  mutations: {
    UPDATE_USER(state, payload = null) {
      if (payload) {
        let {email} = payload.data
        if (['jason@sgara.id', 'arrival@sgara.id'].includes(email)) {
          payload.data.dashboard_role = 'master'
        }
      }

      state.user = payload

      if (payload) {
        localStorage.setItem('sgara-dashboard-psql.user', JSON.stringify(payload))
      } else {
        localStorage.removeItem('sgara-dashboard-psql.user')
      }
    }
  },
  actions: {
    async register(context, payload) {
      return await api.post('/registrasi', payload)
    },
    async login({commit, dispatch}, payload) {
      dispatch('loading', true, {root: true})

      const {email, password} = payload

      let data = null
      await api.post('/login/email', {
        email, password,
        dashboard: "dashboard",
      }).then(res => {
        if (!res.error) {
          commit("UPDATE_USER", res.data)
          data = res.data
          router.push('/')
        }
      }).catch(err => {
        console.log(err)
      })
      dispatch('loading', false, {root: true})
      return data
    },
    logout({commit}) {
      localStorage.removeItem('sgara-dashboard-psql.user')
      commit('UPDATE_USER')
      router.push('/login')
    }
  },
  getters: {
    loggedIn: state => state.user ? true : false,
    token: state => state.user ? state.user.token : null,
    user: state => state.user ? state.user : {},
    isSupplyChain: state => state.user ? state.user.data.dashboard_role === 'supply_chain' : false,
  }
}
