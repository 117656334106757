/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Moment */
const moment = require('moment')
require('moment/locale/id')

Vue.use(require('vue-moment'), {
  moment
})

/* Google Maps */
import * as GmapVue from 'gmap-vue'
// import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyDgoC3K5C4sJU9yrSkgy7Dt2MY3U4Ze1xM",
    libraries: "places,drawing,visualization"
  }
})
// Vee Validate
import {ValidationProvider, ValidationObserver} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import {extend} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import {messages} from 'vee-validate/dist/locale/id.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

/* Sweetalert2 */
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Currency
import VueCurrencyInput from 'vue-currency-input'
const pluginOptions = {
  globalOptions: {currency: 'IDR'}
}
Vue.use(VueCurrencyInput, pluginOptions)

/* Custom Lib */
import lib from '@/lib/lib'
Vue.use(lib)

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Default title tag */
const defaultDocumentTitle = 'Sgara'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
