import Vue from 'vue'
import VueRouter from 'vue-router'
import Blank from "@/views/Blank";
import Main from "@/views/Main";

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    component: Main,
    path: '/',
    children: [
      {
        path: '',
        redirect: '/book/overview'
      },
      {
        path: 'book',
        name: 'book',
        component: Blank,
        children: [
          {
            path: 'overview',
            name: 'book-overview',
            component: () => import('../views/Book/BookOverview'),
            meta: {
              auth: true,
              title: 'Book Overview',
              master: true,
            },
          },
          {
            path: 'farms',
            name: 'book-farms',
            component: () => import('../views/Book/BookFarms'),
            meta: {
              auth: true,
              title: 'Book Farms',
              master: true,
            },
          },
          {
            path: 'harvest',
            name: 'book-harvest',
            component: () => import('../views/Book/BookHarvest'),
            meta: {
              auth: true,
              title: 'Book Farms',
              master: true,
            },
          },
        ]
      },
      {
        path: 'hatchery/overview',
        name: 'hatchery-overview',
        component: () => import('../views/Hatchery/HatcheryOverview'),
        meta: {
          auth: true,
          title: 'Hatchery Overview',
          master: true,
        },
      },
      {
        path: 'supply/overview',
        name: 'supply-overview',
        component: () => import('../views/Supply/SupplyOverview'),
        meta: {
          auth: true,
          title: 'Supply Overview',
          master: true,
        },
      },
      {
        path: 'finance',
        name: 'finance',
        component: Blank,
        children: [
          {
            path: 'overview',
            name: 'finance-overview',
            component: () => import('../views/Finance/FinanceOverview'),
            meta: {
              auth: true,
              title: 'Finance Overview',
              master: true,
            },
          },
          {
            path: 'financing',
            name: 'finance-financing',
            component: () => import('../views/Finance/FinanceFinancing'),
            meta: {
              auth: true,
              title: 'Finance Financing',
              master: true,
            },
          },
        ],
      },
      {
        path: 'supply-chain',
        component: Blank,
        children: [
          {
            path: '',
            name: 'supply-chain',
            component: () => import('../views/SupplyChain/NotaHasilPanen'),
            meta: {
              auth: true,
              title: 'Supply Chain'
            },
          },
        ],
      },
      {
        path: 'users',
        name: 'users-menu',
        component: () => import('../views/Users/UsersMenu'),
        meta: {
          auth: true,
          title: 'Users',
          master: true,
        },
      },
    ]
  },
  {
    component: Blank,
    path: '/',
    children: [
      {
        path: 'supply-chain/delivery-note/:delivery_note_id',
        name: 'supply-chain/delivery-note/print',
        component: () => import('../views/SupplyChain/DeliveryNotePrint'),
        meta: {
          auth: true,
          title: 'Surat Jalan',
          isBlank: true,
          print: true,
        }
      },
      {
        path: 'supply-chain/nota-hasil-panen/:nota_panen_id',
        name: 'supply-chain/nota-hasil-panen/print',
        component: () => import('../views/SupplyChain/NotaHasilPanenPrint'),
        meta: {
          auth: true,
          title: 'Nota Hasil Panen',
          isBlank: true,
          print: true,
        }
      },
      {
        path: 'supply-chain/nota-hasil-panen/:nota_panen_id/invoice',
        name: 'supply-chain/nota-hasil-panen/print/invoice',
        component: () => import('../views/SupplyChain/NotaHasilPanenPrintInvoice'),
        meta: {
          auth: true,
          title: 'Nota Pembelian',
          isBlank: true,
          print: true,
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/Login'),
    meta: {
      auth: false,
      title: 'Login',
      isBlank: true,
    },
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

router.beforeEach((to, from, next) => {
  let user = store.getters['auth/loggedIn']
  let userData = store.getters['auth/user']

  if (to.meta.auth && !user) return router.push('/login')
  else if (!to.meta.auth && user) return router.push('/')

  if (to.meta.master) {
    if (!(userData.data.email === 'jason@sgara.id' || userData.data.email === 'arrival@sgara.id'))
      return  router.push('/supply-chain')
  }

  let html = document.querySelector('html')

  if (to.meta.isBlank) html.className = ""
  else html.className = "has-aside-left has-aside-mobile-transition has-navbar-fixed-top has-aside-expanded"

  next()
})

export default router
