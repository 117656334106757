import api from "@/lib/api";

const farm = {
  namespaced: true,
  state: {
    farms: {
      data: [],
      loading: false
    },
    metrics: {
      farms: {
        value: 0,
        loading: false,
      },
      ponds: {
        value: 0,
        loading: false,
      },
      free_trial_ponds: {
        value: 0,
        loading: false,
      },
      subscribed_ponds: {
        value: 0,
        loading: false,
      },
      retention_table: {
        value: 0,
        loading: false,
      },
      ponds_in_stock: {
        value: 0,
        loading: false,
      },
      actual_shrimp_stock: {
        value: 0,
        loading: false,
      },
      shrimp_partial_harvest: {
        value: 0,
        loading: false,
      },
      shrimp_total_harvest: {
        value: 0,
        loading: false,
      },
    },
    shrimp_stock_location: {
      data: [],
      loading: false,
    },
    shrimp_disease_location: {
      data: [],
      loading: false,
    },
    shrimp_death_location: {
      data: [],
      loading: false,
    },
    chart: {
      DAF: {
        data: [],
        loading: false,
      },
      MAF: {
        data: [],
        loading: false,
      },
      DFA: {
        data: [],
        loading: false,
      },
      MFA: {
        data: [],
        loading: false,
      },
      NOF_Daily: {
        data: [],
        loading: false,
      },
      NOF_Monthly: {
        data: [],
        loading: false,
      },
      DAP: {
        data: [],
        loading: false,
      },
      MAP: {
        data: [],
        loading: false,
      },
      DPA: {
        data: [],
        loading: false,
      },
      NOP_Daily: {
        data: [],
        loading: false,
      },
      NOP_Monthly: {
        data: [],
        loading: false,
      },
      disease: {
        data: [],
        loading: false,
      },
      death: {
        data: [],
        loading: false,
      },
      retention_table: {
        data: [],
        loading: false,
      },
    }
  },
  mutations: {
    UPDATE_FARMS(state, payload) {
      state.farms.data = payload.data
      state.farms.loading = payload.loading
    },
    UPDATE_METRICS(state, payload) {
      state.metrics[payload.key].value = payload.value
      state.metrics[payload.key].loading = payload.loading
    },
    UPDATE_SHRIMP_STOCK_LOCATION(state, payload) {
      state.shrimp_stock_location.data = payload.data
      state.shrimp_stock_location.loading = payload.loading
    },
    UPDATE_SHRIMP_DISEASE_LOCATION(state, payload) {
      state.shrimp_disease_location.data = payload.data
      state.shrimp_disease_location.loading = payload.loading
    },
    UPDATE_SHRIMP_DEATH_LOCATION(state, payload) {
      state.shrimp_death_location.data = payload.data
      state.shrimp_death_location.loading = payload.loading
    },
    UPDATE_CHART(state, payload) {
      state.chart[payload.key].data = payload.data
      state.chart[payload.key].loading = payload.loading
    },
  },
  actions: {
    getFarms({commit}) {
      commit('UPDATE_FARMS', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/farm').then(res => {
          if (!res.error) commit('UPDATE_FARMS', {
            data: res.data.map(e => {
              e.expired_at = e.day_elapsed
              return e
            }).sort((a, b) => a.name.localeCompare(b.name, undefined, {
              sensitivity: 'base',
              numeric: true
            })),
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    deleteFarm(context, farmId) {
      return new Promise((resolve, reject) => {
        api
          .destroy('/internal/farm/' + farmId + '/delete/complete')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateFarm(context, payload) {
      const {farm_id, status} = payload

      return new Promise((resolve, reject) => {
        api
          .put('/internal/farm/' + farm_id + '/update/status', {
            status,
          }, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getFarmInfo(context, farmId) {
      return new Promise((resolve, reject) => {
        api
          .get('/internal/farm/' + farmId + '/info')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    countFarms({commit}) {
      commit('UPDATE_METRICS', {
        key: 'farms',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/farm/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'farms',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'farms',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countPonds({commit}) {
      commit('UPDATE_METRICS', {
        key: 'ponds',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/pond/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'ponds',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'ponds',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countPondsInStock({commit}) {
      commit('UPDATE_METRICS', {
        key: 'ponds_in_stock',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/stock/pond/total').then(res => {
          let stock = res.data.find(e => e.status === 'stock')
          commit('UPDATE_METRICS', {
            key: 'ponds_in_stock',
            value: stock.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'ponds_in_stock',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countActualShrimpInStock({commit}) {
      commit('UPDATE_METRICS', {
        key: 'actual_shrimp_stock',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/stock/actual/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'actual_shrimp_stock',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'actual_shrimp_stock',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countShrimpInPartialHarvest({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'shrimp_partial_harvest',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest/partial/fresh/total', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'shrimp_partial_harvest',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'shrimp_partial_harvest',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countShrimpInTotalHarvest({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'shrimp_total_harvest',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/harvest/fresh/total', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'shrimp_total_harvest',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'shrimp_total_harvest',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getShrimpStockLocation({commit}, payload) {
      commit('UPDATE_SHRIMP_STOCK_LOCATION', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/stock/location', payload).then(res => {
          commit('UPDATE_SHRIMP_STOCK_LOCATION', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_SHRIMP_STOCK_LOCATION', {
            data: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getShrimpDiseaseLocation({commit}, payload) {
      commit('UPDATE_SHRIMP_DISEASE_LOCATION', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/disease/location', payload).then(res => {
          commit('UPDATE_SHRIMP_DISEASE_LOCATION', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_SHRIMP_DISEASE_LOCATION', {
            data: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getShrimpDeathLocation({commit}, payload) {
      commit('UPDATE_SHRIMP_DEATH_LOCATION', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/death/location', payload).then(res => {
          commit('UPDATE_SHRIMP_DEATH_LOCATION', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_SHRIMP_DEATH_LOCATION', {
            data: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDailyActiveFarms({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'DAF',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/daily/active/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'farm',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'DAF',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'DAF',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getMonthlyActiveFarms({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'MAF',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/monthly/active/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'farm',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'MAF',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'MAF',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDailyFarmsActivity({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'DFA',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/daily/activity/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'farm',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'DFA',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'DFA',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getMonthlyFarmsActivity({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'MFA',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/monthly/farm/activity/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'farm',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'MFA',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'MFA',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDailyNewFarms({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'NOF_Daily',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/daily/new/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'farm',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'NOF_Daily',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'NOF_Daily',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getMonthlyNewFarms({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'NOF_Monthly',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/monthly/new/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'farm',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'NOF_Monthly',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'NOF_Monthly',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDailyActivePonds({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'DAP',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/daily/active/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'pond',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'DAP',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'DAP',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getMonthlyActivePonds({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'MAP',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/monthly/active/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'pond',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'MAP',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'MAP',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDailyPondsActivity({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'DPA',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/daily/activity/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'pond',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'DPA',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'DPA',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDailyNewPonds({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'NOP_Daily',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/daily/new/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'pond',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'NOP_Daily',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'NOP_Daily',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getMonthlyNewPonds({commit}, {from, to}) {
      commit('UPDATE_CHART', {
        key: 'NOP_Monthly',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/monthly/new/total', {
          from: from.valueOf(),
          to: to.valueOf(),
          type: 'pond',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'NOP_Monthly',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'NOP_Monthly',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDiseasePerMonth({commit}, {month, year}) {
      commit('UPDATE_CHART', {
        key: 'disease',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/monthly/death/disease/total', {
          month,
          year,
          type: 'pond',
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'disease',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'disease',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getDeathPerMonth({commit}, {month, year}) {
      commit('UPDATE_CHART', {
        key: 'death',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/chart/disease', {
          month, year,
        }).then(res => {
          res.data = res.data.reduce((a, b) => {
            let find = a.find(e => e.city === b.city)

            if (!find) a.push(b)

            return a
          }, [])
          commit('UPDATE_CHART', {
            key: 'death',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'death',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    getRetentionTable({commit}, {type = 'farm', year}) {
      commit('UPDATE_CHART', {
        key: 'retention_table',
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/book/farm/chart/retention/total', {
          type, year,
        }).then(res => {
          commit('UPDATE_CHART', {
            key: 'retention_table',
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_CHART', {
            key: 'retention_table',
            value: [],
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
  }
}

export default farm
