import api from "@/lib/api";

const finance = {
  namespaced: true,
  state: {
    farms: {
      data: [],
      loading: false,
    },
    metrics: {
      mentored_farms: {
        value: 0,
        loading: false,
      },
      mentored_ponds: {
        value: 0,
        loading: false,
      },
      products_sold: {
        value: 0,
        loading: false,
      },
    },
  },
  mutations: {
    UPDATE_FARMS(state, payload) {
      state.farms.data = payload.data
      state.farms.loading = payload.loading
    },
    UPDATE_METRICS(state, payload) {
      state.metrics[payload.key].value = payload.value
      state.metrics[payload.key].loading = payload.loading
    },
  },
  actions: {
    getMentoredFarms({commit}) {
      commit('UPDATE_FARMS', {
        data: [],
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/farm/pendampingan').then(res => {
          if (!res.error) commit('UPDATE_FARMS', {
            data: res.data.map(e => {
              e.expired_at = e.day_elapsed
              return e
            }),
            loading: false
          })
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    countMentoredFarms({commit}) {
      commit('UPDATE_METRICS', {
        key: 'mentored_farms',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/farm/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'mentored_farms',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'mentored_farms',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countMentoredPonds({commit}) {
      commit('UPDATE_METRICS', {
        key: 'mentored_ponds',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/pond/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'mentored_ponds',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'mentored_ponds',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countProductsSold({commit}) {
      commit('UPDATE_METRICS', {
        key: 'products_sold',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/product/sold/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'products_sold',
            value: res.data.reduce((a, b) => a + b.total, 0),
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'products_sold',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },

    checkFarmCycle({dispatch}, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/farm/cycle', {
          farm_id,
        }).then(res => {
          if (!res.error && res.data.total) {
            Promise.all([
              dispatch('getHarvestLastCycle', farm_id),
              dispatch('getFcrSrLastCycle', farm_id),
              dispatch('getFeedQtyLastCycle', farm_id),
              dispatch('validatingSgaraSOP', farm_id),
              dispatch('getDataRecording', farm_id),
              dispatch('getHatcheryUsed', farm_id),
            ]).then(response => {
              response[0].type = 'harvest-cycle'
              response[1].type = 'fcr-sr'
              response[2].type = 'feed-qty'
              response[3].type = 'sop'
              response[4].type = 'data-recording'
              response[5].type = 'hatchery'
              resolve(response)
            }).catch(err => {
              reject(err)
            })
          } else {
            reject(null)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    getHarvestLastCycle(context, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/harvest/total', {
          farm_id,
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getFcrSrLastCycle(context, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/fcr-sr', {
          farm_id,
          timediff: new Date().getTimezoneOffset() * -1,
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getFeedQtyLastCycle(context, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/feed/total', {
          farm_id,
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    validatingSgaraSOP(context, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/validate', {
          farm_id,
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getDataRecording(context, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/farm/cycle', {
          farm_id,
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getHatcheryUsed(context, farm_id) {
      return new Promise((resolve, reject) => {
        api.get('/dashboard/finance/company', {
          farm_id,
        }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    calculateCreditScoring(context, payload) {
      return new Promise((resolve, reject) => {
        api.post('/dashboard/finance/credit-scoring', payload, false).then(res => {
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
  },
}

export default finance
