import api from "@/lib/api";

const supply = {
  namespaced: true,
  state: {
    orders: {
      data: [],
      loading: false,
    },
    metrics: {
      orders: {
        value: 0,
        loading: false,
      },
      total_qty: {
        value: 0,
        loading: false,
      },
      finished_qty: {
        value: 0,
        loading: false,
      },
      unfinished_qty: {
        value: 0,
        loading: false,
      },
      total_nominal: {
        value: 0,
        loading: false,
      },
      finished_nominal: {
        value: 0,
        loading: false,
      },
      unfinished_nominal: {
        value: 0,
        loading: false,
      },
      total_feed: {
        value: 0,
        loading: false,
      },
      total_probiotics: {
        value: 0,
        loading: false,
      },
    },
  },
  mutations: {
    UPDATE_ORDERS(state, payload) {
      state.orders.data = payload.data
      state.orders.loading = payload.loading
    },
    UPDATE_METRICS(state, payload) {
      state.metrics[payload.key].value = payload.value
      state.metrics[payload.key].loading = payload.loading
    },
  },
  actions: {
    getOrders({commit}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_ORDERS', {
          data: [],
          loading: true
        })

        api.get('/internal/order').then(res => {
          if (!res.error) commit('UPDATE_ORDERS', {
            data: res.data.map(e => {
              if (e.doc_id) e.short_order_id = e.doc_id.substr(-4).toUpperCase()
              else e.short_order_id = e.user_id + "" + e.order_id
              e.full_name = e.first_name + " " + e.last_name
              e.product_price = e.product_orders.reduce((a,b) => {
                a += b.price * b.qty

                return a
              }, 0)
              e.total_price = e.product_price + e.shipping_price
              switch (e.status) {
                case -1:
                  e.status_label = 'Canceled'
                  break
                case 1:
                  e.status_label = 'Processed'
                  break
                case 2:
                  e.status_label = 'Shipped'
                  break
                case 3:
                  e.status_label = 'Received'
                  break
                default:
                  e.status_label = 'Pending'
              }
              return e
            }),
            loading: false
          })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateOrder(context, payload) {
      const {
        shipping_date,
        shipping_price,
        completed_date,
        pending_date,
        process_date,
        product_price,
        total_price,
        order_id,
        product_orders
      } = payload

      let status = 0

      if (process_date) status = 1
      if (shipping_date) status = 2
      if (completed_date) status = 3

      return new Promise((resolve, reject) => {
        api
          .put('/internal/order/' + order_id + '/update', {
            completed_date,
            pending_date,
            process_date,
            product_price,
            shipping_date,
            shipping_price,
            total_price,
            status,
            product_orders,
          }, false)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteOrder(context, orderId) {
      return new Promise((resolve, reject) => {
        api
          .destroy('/internal/order/' + orderId + '/delete')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    countOrders({commit}) {
      commit('UPDATE_METRICS', {
        key: 'orders',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/order/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'orders',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'orders',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countTotalQty({commit}) {
      commit('UPDATE_METRICS', {
        key: 'total_qty',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/order-qty/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'total_qty',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'total_qty',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countFinishedQty({commit}) {
      commit('UPDATE_METRICS', {
        key: 'finished_qty',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/order-finished-qty/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'finished_qty',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'finished_qty',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countUnfinishedQty({commit}) {
      commit('UPDATE_METRICS', {
        key: 'unfinished_qty',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/order-unfinished-qty/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'unfinished_qty',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'unfinished_qty',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countTotalNominal({commit}) {
      commit('UPDATE_METRICS', {
        key: 'total_nominal',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/order-price/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'total_nominal',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'total_nominal',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countFinishedNominal({commit}) {
      commit('UPDATE_METRICS', {
        key: 'finished_nominal',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/internal/order-finished-price/total').then(res => {
          commit('UPDATE_METRICS', {
            key: 'finished_nominal',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'finished_nominal',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
    countTotalFeed({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'total_feed',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/supply/order/product/feed', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'total_feed',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'total_feed',
            value: 0,
            loading: false
          })
          reject(err)
        })
      })
    },
    countTotalProbiotics({commit}, payload) {
      commit('UPDATE_METRICS', {
        key: 'total_probiotics',
        value: 0,
        loading: true
      })

      return new Promise((resolve, reject) => {
        api.get('/dashboard/supply/order/product/probiotics', payload).then(res => {
          commit('UPDATE_METRICS', {
            key: 'total_probiotics',
            value: res.data.total,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          commit('UPDATE_METRICS', {
            key: 'total_probiotics',
            value: 0,
            loading: false
          })
          console.log(err)
          reject(err)
        })
      })
    },
  },
}

export default supply
