import api from "@/lib/api";

const company = {
  namespaced: true,
  state: {
    companies: {
      data: [],
      loading: false
    }
  },
  mutations: {
    UPDATE_COMPANIES(state, payload) {
      state.companies.data = payload.data
      state.companies.loading = payload.loading
    },
  },
  actions: {
    getCompanies({commit}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_COMPANIES', {
          data: [],
          loading: true
        })

        api.get('/internal/company').then(res => {
          if (!res.error) commit('UPDATE_COMPANIES', {
            data: res.data,
            loading: false
          })
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  }
}

export default company
